import algoliasearch from 'algoliasearch'

// Initialize the Algolia client
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
)
// indexName, query, filters = '', page = 0
const searchWithAlgolia = async (config) => {
  const { query, page, filters, hitsPerPage, index } = config
  if (!config || config === {}) {
    console.log('no config found')
    return []
  }
  try {
    const createdIndex = searchClient.initIndex(index)
    return await createdIndex.search(query ?? '', {
      hitsPerPage,
      facets: ['industry'],
      page,
      filters,
    })
  } catch (err) {
    console.error('Error fetching Algolia results:', err)
    return {}
  }
}

export default searchWithAlgolia
