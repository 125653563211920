import './App.css'

import HomePage from './pages/Home'
import AboutPage from './pages/about'
import PricingPage from './pages/pricing'
import CheckoutPage from './pages/checkout'
import ContactPage from './pages/contact'
import JobsPage from './pages/Jobs'
import JobDetailPage from './pages/JobDetails/jobDetail'
import JobPrintPage from './pages/JobDetails/jobPrint'
import PaymentCompletePage from './pages/paymentcomplete'

import SignPage from './pages/signin'
import RegisterPage from './pages/register'
import ResetPage from './pages/reset'
import ProfilePage from './pages/account/profile'
import ProfileJobPage from './pages/account/job'
import { withAuthentication } from './session'

import { Route, Routes, useLocation } from 'react-router-dom'
import { useLayoutEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import 'react-datepicker/dist/react-datepicker.css'
import TermsAndConditionsPage from './pages/TermsAndConditions'
import PrivacyPolicy from './pages/PrivacyPolicy'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const Wrapper = ({ children }) => {
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])
  return children
}

function App() {
  return (
    <div className="App">
      <Wrapper>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route
            path="checkout"
            element={
              <Elements stripe={stripePromise}>
                <CheckoutPage />
              </Elements>
            }
          />
          <Route path="contact" element={<ContactPage />} />
          <Route path="login" element={<SignPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="reset" element={<ResetPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="job" element={<ProfileJobPage />} />
          <Route path="job/:id" element={<ProfileJobPage />} />
          <Route path="jobs" element={<JobsPage />} />
          <Route path="payment-complete" element={<PaymentCompletePage />} />
          <Route path="jobs/:id" element={<JobDetailPage />} />
          <Route path="print/:id" element={<JobPrintPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsAndConditionsPage />} />
        </Routes>
      </Wrapper>
    </div>
  )
}

export default withAuthentication(App)
